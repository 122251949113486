import Axios from "axios"

const Api = Axios.create()

Api.interceptors.request.use(async config => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers['x-access-token'] = token
    }

    return config
})

export default Api