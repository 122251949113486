import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class CursosCadastroEditarView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            id: props.match.params.id,
            modulosContainer: [],
            modulosGlobalKey: 0,
            fields: {
                codigo: "",
                nome: "",
                observacoes: "",
                cursos: [],
                modulos: []
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()
        let stateModulos = []
        let proceed = true
        let jQueryLoop = jQuery('#modulosContainer').find('.modulo-item')

        await jQueryLoop.each((index, item) => {
            let nome = jQuery(item).find('[name="moduloNome[]"]').val()
            let duracao = jQuery(item).find('[name="moduloDuracao[]"]').val()

            if (
                nome.length <= 0 ||
                !duracao
            ) {
                SweetAlert({
                    title: "Atenção!",
                    text: "Ao cadastrar um novo módulo, o seu nome e duração devem estar preenchidos.",
                    icon: "error",
                })

                proceed = false
            } else {
                stateModulos.push({
                    id: String(index).padStart(3, "0"),
                    nome: nome,
                    duracao: duracao,
                })
            }
        })

        if (proceed) {
            if (
                this.state.fields.codigo.length <= 0 ||
                this.state.fields.nome.length <= 0
            ) {
                SweetAlert(
                    "Erro",
                    "Por favor, verifique se o codigo e nome estão preenchidos corretamente."
                )
            } else {
                SweetAlert({
                    text: "Tudo certo! Agora vamos salvar o cadastro.",
                    button: {
                        text: "Ok, vou aguardar"
                    }
                })

                let combosArr = []

                jQuery('#combosContainer option').each((index, item) => {
                    if (jQuery(item).is(':selected')) {
                        combosArr.push(jQuery(item).val())
                    }
                })

                Axios.patch(
                    "https://servicecurso.the-cyberpunk.com/curso/" + this.state.id,
                    {
                        codigo: this.state.fields.codigo,
                        nome: this.state.fields.nome,
                        observacoes: this.state.fields.observacoes,
                        modulos: stateModulos
                    }
                )
                    .then(async (responseData) => {
                        let cursoID = responseData.data._id

                        let originalCombos = this.state.originalCombos
                        let combosToRemove = originalCombos.filter(function (el) {
                            return combosArr.indexOf(el) < 0
                        })

                        if (combosToRemove.length > 0) {
                            for (let g in combosToRemove) {
                                await Axios.patch(
                                    "https://servicecombo.the-cyberpunk.com/combo/" + combosToRemove[g],
                                    {
                                        $pull: {
                                            cursos: cursoID
                                        }
                                    }
                                )
                            }
                        }

                        for (let c in combosArr) {
                            await Axios.patch(
                                "https://servicecombo.the-cyberpunk.com/combo/" + combosArr[c],
                                {
                                    $push: {
                                        cursos: cursoID
                                    }
                                }
                            )
                        }

                        SweetAlert({
                            title: "Tudo certo!",
                            text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                            icon: "success",
                            button: {
                                text: "Legal!"
                            }
                        })
                            .then((response) => {
                                if (response) {
                                    window.location.href = '/admin/cursos'
                                }
                            })
                    })
                    .catch((err) => {
                        SweetAlert({
                            title: "Erro",
                            text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                            icon: 'error'
                        })
                    })
            }
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    handleChangeModulo = async (event, index, name) => {
        let {value} = event.target
        let modulos = this.state.fields.modulos

        modulos[index][name] = value

        await this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                modulos: modulos
            }
        }))
    }

    addModulo = (event) => {
        let o = [...this.state.modulosContainer]
        let key = this.state.modulosGlobalKey

        o.push(
            <div
                key={key}
                className="d-block pt-3 px-3 border-bottom"
                style={{
                    backgroundColor: "#dedede"
                }}
            >
                <div className="row align-items-end justify-content-start modulo-item">
                    <div className="col-12 col-md-7">
                        <div className="form-group">
                            <label className="d-block">
                                <small className="form-text text-muted text-left mb-2">
                                    Nome do Módulo
                                </small>
                                <input
                                    type="text"
                                    className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                    name="moduloNome[]"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className="d-block">
                                <small className="form-text text-muted text-left mb-2">
                                    Duração (horas)
                                </small>
                                <input
                                    type="number"
                                    min={0}
                                    max={999}
                                    step={1}
                                    className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                    name="moduloDuracao[]"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <button className="btn btn-danger mb-3" onClick={() => this.removeModulo(key)}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        )

        this.setState({
            modulosContainer: o,
            modulosGlobalKey: key + 1
        })
    }

    removeOldModulo = (event) => {
        jQuery(event.target).parents('.modulo-item').parent().remove()
    }

    removeModulo = (key) => {
        let modulos = [...this.state.modulosContainer]

        modulos.forEach((item, index) => {
            if (item.key == key) {
                modulos.splice(index, 1)
            }
        })

        this.setState({
            modulosContainer: modulos
        })
    }

    async componentDidMount() {
        let curso = await Axios.get('https://servicecurso.the-cyberpunk.com/curso/' + this.state.id)
        let cursoData = curso.data
        let combosData = await Axios.get('https://servicecombo.the-cyberpunk.com/combos')

        let combosList = []
        let originalCombos = []
        combosData.data.map((item, index) => {
            let selected = item.cursos.indexOf(this.state.id) >= 0

            if (selected) {
                originalCombos.push(item._id)
            }

            combosList.push(
                <option
                    key={index}
                    selected={selected}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.codigo} - {item.nome}
                </option>
            )
        })

        await cursoData.modulos.map((item, index) => {
            cursoData['modulos'][index] = {
                nome: item.nome,
                duracao: item.duracao
            }
        })

        await this.setState({
            fields: cursoData,
            done: true,
            originalCombos: originalCombos,
            combosOptions: combosList,
            modulosGlobalKey: cursoData.modulos.length
        })
    }

    render() {
        let RenderModulosLoaded = []

        this.state.fields.modulos.forEach((item, index) => {
            RenderModulosLoaded.push(
                <div
                    key={index}
                    className="d-block pt-3 px-3 border-bottom"
                    style={{
                        backgroundColor: "#dedede"
                    }}
                >
                    <div className="row align-items-end justify-content-start modulo-item">
                        <div className="col-12 col-md-7">
                            <div className="form-group">
                                <label className="d-block">
                                    <small className="form-text text-muted text-left mb-2">
                                        Nome do Módulo
                                    </small>
                                    <input
                                        type="text"
                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                        name="moduloNome[]"
                                        value={this.state.fields.modulos[index].nome}
                                        onChange={(e) => {
                                            this.handleChangeModulo(e, index, 'nome')
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="d-block">
                                    <small className="form-text text-muted text-left mb-2">
                                        Duração (horas)
                                    </small>
                                    <input
                                        type="number"
                                        min={0}
                                        max={999}
                                        step={1}
                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                        name="moduloDuracao[]"
                                        value={this.state.fields.modulos[index].duracao}
                                        onChange={(e) => {
                                            this.handleChangeModulo(e, index, 'duracao')
                                        }}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-12 col-md-1">
                            <button className="btn btn-danger mb-3" onClick={this.removeOldModulo}>
                                <i className="fa fa-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            )
        })

        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="cursos-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de novo
                    Curso</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="codigo">
                                            <small id="namelabel" className="form-text text-muted mb-2">
                                                Código do Curso
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="codigo"
                                                name="codigo"
                                                value={this.state.fields.codigo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="nomelabel" className="form-text text-muted mb-2">
                                                Nome do Curso
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="observacoes">
                                            <small id="observacoeslabel" className="form-text text-muted mb-2">
                                                Observações
                                            </small>
                                            <textarea
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="observacoes"
                                                name="observacoes"
                                                rows={5}
                                                value={this.state.fields.observacoes}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="combos">
                                            <small id="comboslabel" className="form-text text-muted mb-2">
                                                Combos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="combosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"combosReturn"}>
                                                                {this.state.combosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar combos"
                                                            onKeyUp={this.searchCombos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="modulos">
                                            <small id="moduloslabel" className="form-text text-muted mb-2">
                                                Módulos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="modulosContainer" className={"rounded"}>
                                                            <div className="old-modules">
                                                                {RenderModulosLoaded}
                                                            </div>
                                                            {this.state.modulosContainer}
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <button onClick={this.addModulo}
                                                                className="btn btn-primary text-white">Novo
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/cursos"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CursosCadastroEditarView;