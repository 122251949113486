import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class PerfisAlunosView extends Component {
    constructor(props) {
        super(props);
        const Scope = this;

        this.state = {
            dadosPerfis: [],
            done: false,
            TableColumns: [
                {
                    name: "Matrícula",
                    selector: "matricula",
                    sortable: true,
                    width: "10%"
                },
                {
                    name: "Nome",
                    selector: "nome",
                    sortable: true,
                    width: "30%"
                },
                {
                    name: "E-mail",
                    selector: "email",
                    sortable: true,
                    width: "20%"
                },
                {
                    name: "Status",
                    selector: "status",
                    width: "10%",
                    cell: (row) => (
                        <div className={"d-inline-block"}>
                            {row.status ? 'Ativo' : 'Inativo'}
                        </div>
                    ),
                    sortable: true
                },
                {
                    name: "Ações",
                    width: "30%",
                    cell: (row) => (
                        <div className="d-flex align-items-center">
                            <Link to={"/admin/alunos/editar/" + row._id}
                                  className="btn btn-primary text-white btn-sm mr-1">Editar
                            </Link>
                            <button onClick={() => Scope.deletePerfil(row._id)}
                                    className="btn btn-danger btn-sm mr-1">Excluir
                            </button>
                            {row.status == 1 ? (
                                <button onClick={() => Scope.desativarPerfil(row._id)}
                                        className="btn btn-secondary btn-sm">Desativar
                                </button>
                            ) : (
                                <button onClick={() => Scope.ativarPerfil(row._id)}
                                        className="btn btn-success text-white btn-sm">Ativar
                                </button>
                            )}
                        </div>
                    )
                }
            ]
        }
    }

    ativarPerfil = (PerfilID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar este perfil?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativa-lo']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceperfil.the-cyberpunk.com/aluno/' + PerfilID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Perfil ativado.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarPerfil = (PerfilID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar este perfil?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativa-lo']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceperfil.the-cyberpunk.com/aluno/' + PerfilID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Perfil desativado.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    deletePerfil = (PerfilID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer excluir este perfil?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero excluir']
        })
            .then((response) => {
                if (response) {
                    Axios.delete('https://serviceperfil.the-cyberpunk.com/aluno/' + PerfilID)
                        .then(async () => {
                            let turmaInfo = await Axios.post('https://serviceturma.the-cyberpunk.com/turmas-filter', {
                                alunos: PerfilID
                            })

                            Axios.patch(
                                'https://serviceturma.the-cyberpunk.com/turma/' + turmaInfo.data[0]._id,
                                {
                                    $pull: {
                                        'alunos': PerfilID
                                    },
                                    $inc: {
                                        'vagas': 1
                                    }
                                }
                            )
                                .then(() => {
                                    SweetAlert(
                                        'Pronto!',
                                        'Perfil excluído.',
                                        'success'
                                    ).then(() => {
                                        window.location.reload()
                                    })
                                })
                        })
                }
            })
    }

    loadPerfis = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://serviceperfil.the-cyberpunk.com/alunos').then((responseData) => {
            this.setState({
                dadosPerfis: responseData.data,
                done: true
            })
        })
    }

    async componentDidMount() {
        await this.loadPerfis()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="perfis-alunos" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Alunos</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosPerfis}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="matricula"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/alunos/novo"}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Cadastrar novo aluno
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PerfisAlunosView;