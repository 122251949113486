// CSS
import "./AlunoLiveView.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import Axios from 'axios'

// Components
import ReactLoading from "react-loading";

class AlunoLiveView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            callInfo: null
        }
    }

    async componentDidMount() {
        let callInfo = await Axios.get('https://servicecall.the-cyberpunk.com/call/' + this.props.match.params.callId)
        this.setState({
            done: true,
            callInfo: callInfo.data
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div className={"mt-4"}>
                <div className="container-fluid mb-5 pb-md-4">
                    <div className="row">
                        <section id="embed-live" className="col-12 px-3 px-md-5 my-5">
                            <div
                                className="video w-100 d-flex flex-column align-items-center justify-content-center py-5 bg-dark">
                                <a href={this.state.callInfo.join_url}
                                   className="open-zoom px-3 py-2 px-md-5 py-md-3 bg-success text-white"
                                   target={"_blank"}>
                                    <div className="arrow-right"></div>
                                    Abrir no ZOOM
                                </a>
                                <h4 className="font-weight-bold text-center text-white">
                                    {this.state.callInfo.topic} <br/>
                                    <small className={"d-block col-12 col-md-10 px-4 mt-2 mx-auto"}>{this.state.callInfo.agenda}</small>
                                </h4>
                                <div className="d-block my-3 text-white">
                                    <strong>Duração: </strong>{this.state.callInfo.duration} minutos
                                </div>
                                <small className="d-block text-white mt-2">
                                    Clique em "Abrir no ZOOM" para assistir
                                </small>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        )
    }
}

export default AlunoLiveView