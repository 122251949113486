// CSS
import "./AtividadeCard.css"

// Módulos
import React, {Component} from "react"
import {Link} from "react-router-dom"
import Axios from "axios"
import ReactLoading from "react-loading"

class AtividadeCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false
        }
    }

    async componentDidMount() {
        let professorInfo = await Axios.get('https://serviceperfil.the-cyberpunk.com/professor/' + this.props.atividadeData.professores[0])

        this.setState({
            done: true,
            professorInfo: professorInfo.data
        })
    }

    render() {
        let Flag = null
        let DataInicio = new Date(this.props.atividadeData.data_inicio)
        let DataAvisoAtencao = new Date(this.props.atividadeData.data_termino)
        let DataAvisoNova = new Date(this.props.atividadeData.data_inicio)
        let Today = new Date()

        // 15 dias para mostrar o aviso de atenção
        DataAvisoAtencao.setDate(DataAvisoAtencao.getDate() - 15)

        // 30 dias para mostrar o aviso de ativiade nova
        DataAvisoNova.setDate(DataAvisoNova.getDate() + 30)

        if (Today <= DataAvisoNova) {
            Flag = <div className="ballon-new bg-success text-white px-2">
                <div className="arrow-right"></div>
                Nova
            </div>
        }

        if (Today >= DataAvisoAtencao) {
            Flag = <div className="ballon-new bg-danger text-white px-2">
                <div className="arrow-right"></div>
                Atenção
            </div>
        }

        let AtividadeLink = "/cursos/" + this.props.atividadeData.cursos[0] + "/atividade" + "/" + this.props.atividadeData._id

        DataInicio =
            ((DataInicio.getDate() + 1) <= 9 ? "0" + (DataInicio.getDate() + 1) : (DataInicio.getDate() + 1)) + "/" +
            ((DataInicio.getDate() + 1) <= 9 ? "0" + (DataInicio.getMonth() + 1) : (DataInicio.getMonth() + 1)) + "/" +
            DataInicio.getFullYear()

        return !this.state.done ? (
            <section id="sidebar-right" className="col-12 col-md-4">
                <div id="labels-activities" className="w-100 p-3 bg-white p-3 border border-radius mb-3">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                        </div>
                    </div>
                </div>
            </section>
        ) : (
            <Link to={AtividadeLink} className="card-activity-student text-body item p-3 card mb-4">
                {Flag}

                <div className="row d-flex align-items-center">
                    <div className="col-8 col-md-9">
                        <h2 className="title font-weight-bold">
                            {this.props.atividadeData.titulo}
                        </h2>
                        <p className="teacher">Professor(a): {this.state.professorInfo.nome}</p>
                    </div>
                    <div className="col-4 col-md-3 text-right">
                        <p className="start">
                            Início<br/>
                            {DataInicio}
                        </p>
                    </div>
                </div>
            </Link>
        )
    }
}

export default AtividadeCard