import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class UnidadesCadastroView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            fields: {
                codigo: "",
                nome: "",
                coordenadores: [],
                // turmas: []
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()

        if (
            this.state.fields.codigo.length <= 0 ||
            this.state.fields.nome.length <= 0 ||
            this.state.fields.vagas <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o codigo, nome e vagas estão preenchidos corretamente."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let coordenadoresArr = []
            // let turmasArr = []

            jQuery('#coordenadoresContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    coordenadoresArr.push(jQuery(item).val())
                }
            })
            // jQuery('#turmasContainer option').each((index, item) => {
            //     if (jQuery(item).is(':selected')) {
            //         turmasArr.push(jQuery(item).val())
            //     }
            // })

            await this.setState(prevState => ({
                ...prevState,
                fields: {
                    codigo: this.state.fields.codigo,
                    nome: this.state.fields.nome,
                    coordenadores: coordenadoresArr,
                    // turmas: turmasArr
                }
            }))

            Axios.post(
                "https://serviceunidade.the-cyberpunk.com/unidade",
                this.state.fields
            )
                .then((responseData) => {
                    SweetAlert({
                        title: "Tudo certo!",
                        text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                        .then((response) => {
                            if (response) {
                                window.location.href = '/admin/unidades'
                            }
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    async componentDidMount() {
        let coordenadoresData = await Axios.get('https://serviceperfil.the-cyberpunk.com/coordenadores')
        // let turmasData = await Axios.get('https://serviceturma.the-cyberpunk.com/turmas')

        let coordenadoresList = coordenadoresData.data.map((item, index) =>
            <option key={index} value={item._id} className="btn btn-light text-left w-100">{item.nome}</option>
        )

        // let turmasList = []
        // for (let t in turmasData.data) {
        //     let unidadeInfo = await Axios.post('https://serviceunidade.the-cyberpunk.com/unidades-filter', {
        //         turmas: turmasData.data[t]._id
        //     })
        //
        //     if (unidadeInfo.data.length <= 0) {
        //         turmasList.push(
        //             <option
        //                 key={t}
        //                 value={turmasData.data[t]._id}
        //                 className="btn btn-light text-left w-100"
        //             >
        //                 {turmasData.data[t].codigo} - {turmasData.data[t].nome}
        //             </option>
        //         )
        //     }
        // }

        // let turmasList = turmasData.data.map((item, index) =>
        //     <option key={index} value={item._id} className="btn btn-light text-left w-100">{item.codigo} - {item.nome}</option>
        // )

        this.setState({
            done: true,
            coordenadoresOptions: coordenadoresList,
            // turmasOptions: turmasList
        })
    }

    searchCoordenadores = (event) => {
        let {name, value} = event.target
        jQuery('#coordenadoresContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }
    //
    // searchTurmas = (event) => {
    //     let {name, value} = event.target
    //     jQuery('#turmasContainer option').each((index, item) => {
    //         if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
    //             jQuery(item).addClass("d-none")
    //         } else {
    //             jQuery(item).removeClass("d-none")
    //         }
    //     })
    // }


    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="unidades-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de nova
                    Unidade</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="codigo">
                                            <small id="codigolabel" className="form-text text-muted mb-2">
                                                Código da Unidade
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="codigo"
                                                name="codigo"
                                                value={this.state.fields.codigo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="nomelabel" className="form-text text-muted mb-2">
                                                Nome da Unidade
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="coordenadores">
                                            <small id="coordenadoreslabel" className="form-text text-muted mb-2">
                                                Coordenadores
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="coordenadoresContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"coordenadoresReturn"}>
                                                                {this.state.coordenadoresOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar coordenadores"
                                                            onKeyUp={this.searchCoordenadores}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                {/*<div className="col-12">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <label className="d-block" htmlFor="turmas">*/}
                                {/*            <small id="turmaslabel" className="form-text text-muted mb-2">*/}
                                {/*                Turmas*/}
                                {/*            </small>*/}
                                {/*            <div className="card">*/}
                                {/*                <div className="card-body p-3">*/}
                                {/*                    <div className="d-block text-right">*/}
                                {/*                        <div id="turmasContainer" className={"rounded"}>*/}
                                {/*                            <select multiple*/}
                                {/*                                    className={"form-control custom-select p-0 d-flex flex-column"}*/}
                                {/*                                    name={"turmasReturn"}>*/}
                                {/*                                {this.state.turmasOptions}*/}
                                {/*                            </select>*/}
                                {/*                        </div>*/}
                                {/*                        <hr className="my-3"/>*/}
                                {/*                        <input*/}
                                {/*                            type="text"*/}
                                {/*                            className="form-control"*/}
                                {/*                            placeholder="Pesquisar turmas"*/}
                                {/*                            onKeyUp={this.searchTurmas}*/}
                                {/*                        />*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/unidades"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UnidadesCadastroView;