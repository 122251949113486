// Módulos
import React, {Component} from "react"
import {Redirect, Route} from "react-router-dom"

// Components
import Sidebar from "../components/Sidebar"
import Header from "../components/Header"

// Views
import ReactLoading from "react-loading";

// - Administradores
import AdministradorHomeView from "./administrador/AdministradorHomeView"
import AdministradorPlanejamentoView from "./administrador/AdministradorPlanejamentoView"
import PerfisAdministradoresView from "./administrador/PerfisAdministradoresView"
import PerfisAdministradoresCadastroView from "./administrador/PerfisAdministradoresCadastroView"
import PerfisAdministradoresCadastroEditarView from "./administrador/PerfisAdministradoresCadastroEditarView"
import PerfisCoordenadoresView from "./administrador/PerfisCoordenadoresView"
import PerfisCoordenadoresCadastroView from "./administrador/PerfisCoordenadoresCadastroView"
import PerfisCoordenadoresCadastroEditarView from "./administrador/PerfisCoordenadoresCadastroEditarView"
import PerfisProfessoresView from "./administrador/PerfisProfessoresView"
import PerfisProfessoresCadastroView from "./administrador/PerfisProfessoresCadastroView"
import PerfisProfessoresCadastroEditarView from "./administrador/PerfisProfessoresCadastroEditarView"
import PerfisAlunosView from "./administrador/PerfisAlunosView"
import PerfisAlunosCadastroView from "./administrador/PerfisAlunosCadastroView"
import PerfisAlunosCadastroEditarView from "./administrador/PerfisAlunosCadastroEditarView"
import PerfilPessoalAdministradorView from "./administrador/PerfilPessoalView"
import TurmasView from "./administrador/TurmasView"
import TurmasCadastroView from "./administrador/TurmasCadastroView"
import TurmasCadastroEditarView from "./administrador/TurmasCadastroEditarView"
import UnidadesView from "./administrador/UnidadesView"
import UnidadesCadastroView from "./administrador/UnidadesCadastroView"
import UnidadesCadastroEditarView from "./administrador/UnidadesCadastroEditarView"
import CombosView from "./administrador/CombosView"
import CombosCadastroView from "./administrador/CombosCadastroView"
import CombosCadastroEditarView from "./administrador/CombosCadastroEditarView"
import CursosView from "./administrador/CursosView"
import CursosCadastroView from "./administrador/CursosCadastroView"
import CursosCadastroEditarView from "./administrador/CursosCadastroEditarView"
import AtividadesView from "./administrador/AtividadesView"
import AtividadesCadastroView from "./administrador/AtividadesCadastroView"
import AtividadesCadastroEditarView from "./administrador/AtividadesCadastroEditarView"
import MeetingsView from "./administrador/MeetingsView"
import MeetingsCadastroView from "./administrador/MeetingsCadastroView"

// - Coordenadores
import CoordenadorHomeView from "./coordenador/CoordenadorHomeView"
import PerfilPessoalCoordenadorView from "./coordenador/PerfilPessoalView"

// - Professores
import ProfessorHomeView from "./professor/ProfessorHomeView"
import PerfilPessoalProfessorView from "./professor/PerfilPessoalView"

// - Alunos
import AlunoHomeView from "./aluno/AlunoHomeView"
import AlunoLiveView from "./aluno/AlunoLiveView"
import AlunoAtividadeComplexView from "./aluno/AlunoAtividadeComplexView"
import AlunoCursosView from "./aluno/AlunoCursosView"
import AlunoPerfilView from "./aluno/AlunoPerfilView"
import AlunoMinhasAtividadesView from "./aluno/AlunoMinhasAtividadesView"

class DashboardView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            menuIsOpened: window.outerWidth > 992,
            userData: null
        }
    }

    componentDidMount() {
        this.setState({
            done: true
        })
    }

    handleComponentChange = (userData) => {
        this.setState({
            userData: userData
        })
    }

    handleMenuChange = (menuInfo) => {
        if (window.outerWidth <= 991) {
            this.setState({
                menuIsOpened: menuInfo
            })
        }
    }

    render() {
        if (!localStorage.getItem('token')) {
            return <Redirect to="/login"/>
        }

        let RenderRoutes = null
        if (localStorage.getItem("userRole") === "administrador") {
            RenderRoutes = (
                <div className={"h-100"}>
                    <Route
                        exact
                        path={"/admin/planejamento"}
                        component={AdministradorPlanejamentoView}
                    />
                    <Route
                        exact
                        path={"/admin/administradores"}
                        component={PerfisAdministradoresView}
                    />
                    <Route
                        exact
                        path={"/admin/administradores/novo"}
                        component={PerfisAdministradoresCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/administradores/editar/:id"}
                        component={PerfisAdministradoresCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores"}
                        component={PerfisCoordenadoresView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores/novo"}
                        component={PerfisCoordenadoresCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores/editar/:id"}
                        component={PerfisCoordenadoresCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/professores"}
                        component={PerfisProfessoresView}
                    />
                    <Route
                        exact
                        path={"/admin/professores/novo"}
                        component={PerfisProfessoresCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/professores/editar/:id"}
                        component={PerfisProfessoresCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos"}
                        component={PerfisAlunosView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/novo"}
                        component={PerfisAlunosCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/editar/:id"}
                        component={PerfisAlunosCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/editar-perfil"}
                        component={PerfilPessoalAdministradorView}
                    />
                    <Route
                        exact
                        path={"/admin/turmas/editar/:id"}
                        component={TurmasCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/turmas/nova"}
                        component={TurmasCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/turmas"}
                        component={TurmasView}
                    />
                    <Route
                        exact
                        path={"/admin/unidades/editar/:id"}
                        component={UnidadesCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/unidades/nova"}
                        component={UnidadesCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/unidades"}
                        component={UnidadesView}
                    />
                    <Route
                        exact
                        path={"/admin/combos/editar/:id"}
                        component={CombosCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/combos/novo"}
                        component={CombosCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/combos"}
                        component={CombosView}
                    />
                    <Route
                        exact
                        path={"/admin/cursos/editar/:id"}
                        component={CursosCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/cursos/novo"}
                        component={CursosCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/cursos"}
                        component={CursosView}
                    />
                    <Route
                        exact
                        path={"/admin/atividades/editar/:id"}
                        component={AtividadesCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/atividades/nova/:tipo"}
                        component={AtividadesCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/atividades"}
                        component={AtividadesView}
                    />
                    <Route
                        exact
                        path={"/admin/meetings/nova"}
                        component={MeetingsCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/meetings"}
                        component={MeetingsView}
                    />
                    <Route
                        exact
                        path={"/admin"}
                        component={AdministradorHomeView}
                    />
                </div>
            )
        }

        if (localStorage.getItem("userRole") === "coordenador") {
            RenderRoutes = (
                <div className={"h-100"}>
                    <Route
                        exact
                        path={"/admin/planejamento"}
                        component={AdministradorPlanejamentoView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores"}
                        component={PerfisCoordenadoresView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores/novo"}
                        component={PerfisCoordenadoresCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/coordenadores/editar/:id"}
                        component={PerfisCoordenadoresCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/professores"}
                        component={PerfisProfessoresView}
                    />
                    <Route
                        exact
                        path={"/admin/professores/novo"}
                        component={PerfisProfessoresCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/professores/editar/:id"}
                        component={PerfisProfessoresCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos"}
                        component={PerfisAlunosView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/novo"}
                        component={PerfisAlunosCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/editar/:id"}
                        component={PerfisAlunosCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/editar-perfil"}
                        component={PerfilPessoalCoordenadorView}
                    />
                    <Route
                        exact
                        path={"/admin"}
                        component={CoordenadorHomeView}
                    />
                </div>
            )
        }

        if (localStorage.getItem("userRole") === "professor") {
            RenderRoutes = (
                <div className={"h-100"}>
                    <Route
                        exact
                        path={"/admin/planejamento"}
                        component={AdministradorPlanejamentoView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos"}
                        component={PerfisAlunosView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/novo"}
                        component={PerfisAlunosCadastroView}
                    />
                    <Route
                        exact
                        path={"/admin/alunos/editar/:id"}
                        component={PerfisAlunosCadastroEditarView}
                    />
                    <Route
                        exact
                        path={"/admin/editar-perfil"}
                        component={PerfilPessoalProfessorView}
                    />
                    <Route
                        exact
                        path={"/admin"}
                        component={ProfessorHomeView}
                    />
                </div>
            )
        }

        if (localStorage.getItem("userRole") === "aluno") {
            RenderRoutes = (
                <div className={"h-100"}>
                    <Route
                        exact
                        path={"/minhas-atividades"}
                        component={AlunoMinhasAtividadesView}
                    />
                    <Route
                        exact
                        path={"/meu-perfil"}
                        component={AlunoPerfilView}
                    />
                    <Route
                        exact
                        path={"/atividade/:id"}
                        render={props => <AlunoAtividadeComplexView {...props}/>}
                    />
                    <Route
                        exact
                        path={"/cursos/:id"}
                        render={props => <AlunoCursosView {...props} path={this.props.location.pathname}/>}
                    />
                    <Route
                        exact
                        path={"/cursos/:id/live/:callId"}
                        render={props => <AlunoLiveView {...props}/>}
                    />
                    <Route
                        exact
                        path={"/"}
                        render={props => <AlunoHomeView {...props}
                                                        onHandleComponentChange={this.handleComponentChange}/>}
                    />
                </div>
            )
        }

        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <main className={"row align-items-stretch justify-content-start no-gutters mx-0 w-100 h-100"}>
                <Sidebar
                    role={localStorage.getItem("userRole")}
                    path={this.props.location.pathname}
                    isOpen={this.state.menuIsOpened}
                    menuIsOpened={this.handleMenuChange}
                />

                <section id="panel" className="col-12 col-md-10 px-0 ml-auto">
                    <Header
                        userData={this.state.userData}
                        isOpen={this.state.menuIsOpened}
                        menuIsOpened={this.handleMenuChange}
                    />

                    {RenderRoutes}
                </section>
            </main>
        )
    }
}

export default DashboardView