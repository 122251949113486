import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class CursosView extends Component {
    constructor(props) {
        super(props);
        const Scope = this;

        this.state = {
            dadosCursos: [],
            done: false,
            TableColumns: [
                {
                    name: "Código",
                    selector: "codigo",
                    sortable: true,
                    width: "10%"
                },
                {
                    name: "Nome do Curso",
                    selector: "nome",
                    sortable: true,
                    width: "50%"
                },
                {
                    name: "Status",
                    selector: "status",
                    width: "10%",
                    cell: (row) => (
                        <div className={"d-inline-block"}>
                            {row.status ? 'Ativo' : 'Inativo'}
                        </div>
                    ),
                    sortable: true
                },
                {
                    name: "Ações",
                    width: "30%",
                    cell: (row) => (
                        <div className="d-flex align-items-center">
                            <Link to={"/admin/cursos/editar/" + row._id}
                                  className="btn btn-primary text-white btn-sm mr-1">Editar
                            </Link>
                            <button onClick={() => Scope.deletarCurso(row._id)}
                                    className="btn btn-danger btn-sm mr-1">Excluir
                            </button>
                            {row.status == 1 ? (
                                <button onClick={() => Scope.desativarCurso(row._id)}
                                        className="btn btn-secondary btn-sm">Desativar
                                </button>
                            ) : (
                                <button onClick={() => Scope.ativarCurso(row._id)}
                                        className="btn btn-success text-white btn-sm">Ativar
                                </button>
                            )}
                        </div>
                    )
                }
            ]
        }
    }

    ativarCurso = (CursoID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar este curso?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-lo']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://servicecurso.the-cyberpunk.com/curso/' + CursoID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Curso ativado.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarCurso = (CursoID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar este curso?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativá-lo']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://servicecurso.the-cyberpunk.com/curso/' + CursoID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Curso desativado.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    deletarCurso = (CursoID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer excluir este curso?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero excluir']
        })
            .then((response) => {
                if (response) {
                    Axios.delete('https://servicecurso.the-cyberpunk.com/curso/' + CursoID)
                        .then(async () => {
                            let turmaInfo = await Axios.post('https://serviceturma.the-cyberpunk.com/turmas-filter', {
                                cursos: CursoID
                            })

                            for (let t in turmaInfo.data) {
                                await Axios.patch(
                                    'https://serviceturma.the-cyberpunk.com/turma/' + turmaInfo.data[t]._id,
                                    {
                                        $pull: {
                                            'cursos': CursoID
                                        }
                                    }
                                )
                            }

                            let combosInfo = await Axios.post('https://servicecombo.the-cyberpunk.com/combos-filter', {
                                cursos: CursoID
                            })

                            for (let c in combosInfo.data) {
                                await Axios.patch(
                                    "https://servicecombo.the-cyberpunk.com/combo/" + combosInfo.data[c]._id,
                                    {
                                        $pull: {
                                            'cursos': CursoID
                                        }
                                    }
                                )
                            }

                            SweetAlert(
                                'Pronto!',
                                'Curso excluído.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    loadCursos = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://servicecurso.the-cyberpunk.com/cursos').then((responseData) => {
            this.setState({
                dadosCursos: responseData.data,
                done: true
            })
        })
    }

    async componentDidMount() {
        await this.loadCursos()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="cursos-listagem" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cursos</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosCursos}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="codigo"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/cursos/novo"}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Cadastrar novo curso
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CursosView;