import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class TurmasView extends Component {
    constructor(props) {
        super(props);
        const Scope = this;

        this.state = {
            dadosTurmas: [],
            done: false,
            TableColumns: [
                {
                    name: "Código",
                    selector: "codigo",
                    sortable: true,
                    width: "15%"
                },
                {
                    name: "Nome da Turma",
                    selector: "nome",
                    sortable: true,
                    width: "35%"
                },
                {
                    name: "Vagas",
                    selector: "vagas",
                    sortable: true,
                    width: "10%"
                },
                {
                    name: "Status",
                    selector: "status",
                    width: "10%",
                    cell: (row) => (
                        <div className={"d-inline-block"}>
                            {row.status ? 'Ativo' : 'Inativo'}
                        </div>
                    ),
                    sortable: true
                },
                {
                    name: "Ações",
                    width: "30%",
                    cell: (row) => (
                        <div className="d-flex align-items-center">
                            <Link to={"/admin/turmas/editar/" + row._id}
                                  className="btn btn-primary text-white btn-sm mr-1">Editar
                            </Link>
                            <button onClick={() => Scope.deletarTurma(row._id, row.alunos)}
                                    className="btn btn-danger btn-sm mr-1">Excluir
                            </button>
                            {row.status == 1 ? (
                                <button onClick={() => Scope.desativarTurma(row._id)}
                                        className="btn btn-secondary btn-sm">Desativar
                                </button>
                            ) : (
                                <button onClick={() => Scope.ativarTurma(row._id)}
                                        className="btn btn-success text-white btn-sm">Ativar
                                </button>
                            )}
                        </div>
                    )
                }
            ]
        }
    }

    ativarTurma = (TurmaID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar esta turma?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceturma.the-cyberpunk.com/turma/' + TurmaID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Turma ativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarTurma = (TurmaID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar esta turma?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceturma.the-cyberpunk.com/turma/' + TurmaID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Turma desativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    deletarTurma = (TurmaID, alunosArr) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer excluir esta turma?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero excluir']
        })
            .then((response) => {
                if (response) {
                    Axios.delete('https://serviceturma.the-cyberpunk.com/turma/' + TurmaID)
                        .then(async () => {
                            let unidadeInfo = await Axios.post('https://serviceunidade.the-cyberpunk.com/unidades-filter', {
                                turmas: TurmaID
                            })

                            Axios.patch(
                                'https://serviceunidade.the-cyberpunk.com/unidade/' + unidadeInfo.data[0]._id,
                                {
                                    $pull: {
                                        'turmas': TurmaID
                                    }
                                }
                            )
                                .then(async () => {
                                    for (let a in alunosArr) {
                                        await Axios.patch('https://serviceperfil.the-cyberpunk.com/aluno/' + alunosArr[a], {
                                            turma: ""
                                        })
                                    }

                                    SweetAlert(
                                        'Pronto!',
                                        'Turma excluída.',
                                        'success'
                                    ).then(() => {
                                        window.location.reload()
                                    })
                                })
                        })
                }
            })
    }

    loadTurmas = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://serviceturma.the-cyberpunk.com/turmas').then((responseData) => {
            this.setState({
                dadosTurmas: responseData.data,
                done: true
            })
        })
    }

    async componentDidMount() {
        await this.loadTurmas()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="turmas-listagem" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Turmas</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosTurmas}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="nome"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/turmas/nova"}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Cadastrar nova turma
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TurmasView;