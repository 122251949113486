import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"

class MeetingsCadastroView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            fields: {
                topic: "",
                type: 2,
                // start_time: data+T+hora+:00
                data: "",
                data_final: "",
                hora: "",
                duration: 1,
                timezone: "America/Sao_Paulo",
                agenda: "",
                // recurrence: add follows without preffix
                recurrence_type: 2,
                recurrence_repeat_interval: 1,
                recurrence_weekly_days: "6",
                recurrence_monthly_day: 15,
                recurrence_end_date_time: "",
                cursos: []
            }
        }
    }

    cancelarReuniao = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    criarReuniao = async (event) => {
        event.preventDefault()

        let cursosArr = []

        jQuery('#cursosContainer option').each((index, item) => {
            if (jQuery(item).is(':selected')) {
                cursosArr.push(jQuery(item).val())
            }
        })

        if (
            this.state.fields.recurrence_type == 1 &&
            this.state.fields.recurrence_repeat_interval > 90
        ) {
            SweetAlert(
                "Erro",
                "Para recorrências diárias, o limite de dias é 90."
            )
        } else if (
            this.state.fields.recurrence_type == 2 &&
            this.state.fields.recurrence_repeat_interval > 12
        ) {
            SweetAlert(
                "Erro",
                "Para recorrências semanais, o limite de semanas é 12."
            )
        } else if (
            this.state.fields.recurrence_type == 3 &&
            this.state.fields.recurrence_repeat_interval > 3
        ) {
            SweetAlert(
                "Erro",
                "Para recorrências mensais, o limite de meses é 3."
            )
        } else if (
            this.state.fields.topic.length <= 0 ||
            this.state.fields.topic.data <= 0 ||
            this.state.fields.topic.hora <= 0 ||
            cursosArr.length <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o assunto, data, hora e curso estão preenchidos corretamente."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let recurrenceObj = {}

            // Recorrência diária
            if (this.state.fields.recurrence_type == 1) {
                recurrenceObj = {
                    type: this.state.fields.recurrence_type,
                    repeat_interval: this.state.fields.recurrence_repeat_interval,
                    end_date_time: this.state.fields.data_final + "T00:00:00"
                }
            }

            // Recorrência semanal
            if (this.state.fields.recurrence_type == 2) {
                recurrenceObj = {
                    type: this.state.fields.recurrence_type,
                    repeat_interval: this.state.fields.recurrence_repeat_interval,
                    end_date_time: this.state.fields.data_final + "T00:00:00",
                    weekly_days: this.state.fields.recurrence_weekly_days
                }
            }

            // Recorrência mensal
            if (this.state.fields.recurrence_type == 3) {
                recurrenceObj = {
                    type: this.state.fields.recurrence_type,
                    repeat_interval: this.state.fields.recurrence_repeat_interval,
                    end_date_time: this.state.fields.data_final + "T00:00:00",
                    monthly_day: this.state.fields.recurrence_monthly_day
                }
            }

            // Configurações da video conferência
            let settingsObj = {
                host_video: true,
                join_before_host: true,
                mute_upon_entry: true,
                auto_recording: "cloud"
            }

            let dataSend = {
                topic: this.state.fields.topic,
                type: this.state.fields.type,
                start_time: this.state.fields.data + "T" + this.state.fields.hora + ":00",
                duration: this.state.fields.duration,
                timezone: "America/Sao_Paulo",
                agenda: this.state.fields.agenda,
                recurrence: recurrenceObj,
                settings: settingsObj,
            }

            Axios.post(
                "https://servicecall.the-cyberpunk.com/create-meeting/" + localStorage.getItem('zoomUserId'),
                dataSend
            )
                .then(async (responseData) => {
                    let insertData = responseData.data
                    insertData['cursos'] = cursosArr

                    await Axios.post("https://servicecall.the-cyberpunk.com/call", insertData)
                        .then((insertResponse) => {
                            SweetAlert({
                                title: "Criando a video conferência",
                                text: "Criando a notificação"
                            })

                            Axios.post('https://servicenotificacao.the-cyberpunk.com/notificacao', {
                                tipo: "aula",
                                titulo: "Nova aula ao vivo",
                                descricao: this.state.fields.topic,
                                link: "/cursos/" + cursosArr[0] + "/live/" + insertResponse.data._id,
                                duracao: 7
                            })
                                .then(async (notificacaoData) => {
                                    SweetAlert({
                                        title: "Criando a video conferência",
                                        text: "Disparando notificações para os alunos"
                                    })

                                    let alunosArr = []
                                    for (let c in cursosArr) {
                                        let responseDataCursos = await Axios.post('https://servicecurso.the-cyberpunk.com/curso-alunos/' + cursosArr[c])
                                        responseDataCursos.data.map((aluno, a) => {
                                            if (alunosArr.indexOf(aluno) < 0) {
                                                alunosArr.push(aluno)
                                            }
                                        })
                                    }

                                    for (let a in alunosArr) {
                                        Axios.get('https://serviceperfil.the-cyberpunk.com/aluno/' + alunosArr[a])
                                            .then((alunoData) => {
                                                let notificacoes = alunoData.data.notificacoes
                                                notificacoes.push(notificacaoData.data._id)

                                                Axios.patch('https://serviceperfil.the-cyberpunk.com/aluno/' + alunosArr[a], {
                                                    notificacoes: notificacoes
                                                })
                                            })
                                    }

                                    SweetAlert({
                                        title: "Criando a video conferência",
                                        text: "Tudo certo!"
                                    })
                                        .then((response) => {
                                            if (response) {
                                                window.location.href = '/admin/meetings'
                                            }
                                        })
                                })
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    searchCursos = (event) => {
        let {name, value} = event.target
        jQuery('#cursosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    async componentDidMount() {
        let cursosData = await Axios.get('https://servicecurso.the-cyberpunk.com/cursos')
        let cursosList = cursosData.data.map((item, index) =>
            <option key={index} value={item._id}
                    className="btn btn-light text-left w-100">{item.codigo} - {item.nome}</option>
        )

        this.setState({
            done: true,
            cursosOptions: cursosList
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="meetings-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de nova
                    Vídeo Conferência</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="topic">
                                            <small id="topiclabel" className="form-text text-muted mb-2">
                                                Assunto
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="topic"
                                                name="topic"
                                                value={this.state.fields.topic}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="start_time">
                                            <small id="start_timelabel" className="form-text text-muted mb-2">
                                                Data e Hora de Início
                                            </small>
                                            <div className="row">
                                                <div className="col-6">
                                                    <input
                                                        type="date"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="data"
                                                        name="data"
                                                        value={this.state.fields.data}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <input
                                                        type="time"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="hora"
                                                        name="hora"
                                                        value={this.state.fields.hora}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="duration">
                                            <small id="durationlabel" className="form-text text-muted mb-2">
                                                Duração (minutos)
                                            </small>
                                            <input
                                                type="number"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="duration"
                                                name="duration"
                                                min={1}
                                                max={60}
                                                value={this.state.fields.duration}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="agenda">
                                            <small id="agendalabel" className="form-text text-muted mb-2">
                                                Descrição da Conferência
                                            </small>
                                            <textarea
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="agenda"
                                                name="agenda"
                                                value={this.state.fields.agenda}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="recurrence">
                                            <small id="recurrencelabel" className="form-text text-muted mb-2">
                                                Configurações de Recorrência
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block">
                                                        <div id="recurrenceContainer" className={"rounded"}>
                                                            <div className="row">
                                                                <div className="col-12 col-md-3">
                                                                    <div className="form-group">
                                                                        <label className="d-block"
                                                                               htmlFor="recurrence_type">
                                                                            <small id="recurrence_typelabel"
                                                                                   className="form-text text-muted mb-2">
                                                                                Tipo de recorrência
                                                                            </small>
                                                                            <select
                                                                                className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                                                name="recurrence_type"
                                                                                id="recurrence_type"
                                                                                value={this.state.fields.recurrence_type}
                                                                                onChange={this.handleChange}
                                                                            >
                                                                                <option value="1">Diária</option>
                                                                                <option value="2">Semanal</option>
                                                                                <option value="3">Mensal</option>
                                                                            </select>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-3">
                                                                    <div className="form-group">
                                                                        <label className="d-block"
                                                                               htmlFor="recurrence_repeat_interval">
                                                                            <small id="recurrence_repeat_intervallabel"
                                                                                   className="form-text text-muted mb-2">
                                                                                Recorrência
                                                                            </small>
                                                                            <div className="input-group mb-3">
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control rounded-0 m-0"
                                                                                    id="recurrence_repeat_interval"
                                                                                    name="recurrence_repeat_interval"
                                                                                    min={1}
                                                                                    max={this.state.fields.recurrence_type == 1 ? 90 : (this.state.fields.recurrence_type == 2 ? 12 : 3)}
                                                                                    value={this.state.fields.recurrence_repeat_interval}
                                                                                    onChange={this.handleChange}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <span
                                                                                        className="input-group-text"
                                                                                        id="basic-addon2"
                                                                                    >
                                                                                        <small>
                                                                                            {this.state.fields.recurrence_type == 1 ? 'vezes por dia' : (this.state.fields.recurrence_type == 2 ? 'vezes por semana' : 'vezes por mês')}
                                                                                        </small>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {this.state.fields.recurrence_type == 1 ? (
                                                                    <div className="col-12 col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="d-block">
                                                                                <small
                                                                                    className="form-text text-muted mb-2">
                                                                                    Dia
                                                                                </small>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0 disabled"
                                                                                    disabled
                                                                                    value="Todos os dias"
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {this.state.fields.recurrence_type == 2 ? (
                                                                    <div className="col-12 col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="d-block"
                                                                                   htmlFor="recurrence_weekly_days">
                                                                                <small id="recurrence_weekly_dayslabel"
                                                                                       className="form-text text-muted mb-2">
                                                                                    Dia da semana
                                                                                </small>
                                                                                <select
                                                                                    className="custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                                                    name="recurrence_weekly_days"
                                                                                    id="recurrence_weekly_days"
                                                                                    value={this.state.fields.recurrence_weekly_days}
                                                                                    onChange={this.handleChange}
                                                                                >
                                                                                    <option value="1">Domingos</option>
                                                                                    <option value="2">Segundas</option>
                                                                                    <option value="3">Terças</option>
                                                                                    <option value="4">Quartas</option>
                                                                                    <option value="5">Quintas</option>
                                                                                    <option value="6">Sextas</option>
                                                                                    <option value="7">Sábados</option>
                                                                                </select>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                {this.state.fields.recurrence_type == 3 ? (
                                                                    <div className="col-12 col-md-3">
                                                                        <div className="form-group">
                                                                            <label className="d-block"
                                                                                   htmlFor="recurrence_monthly_day">
                                                                                <small id="recurrence_monthly_daylabel"
                                                                                       className="form-text text-muted mb-2">
                                                                                    Dia do mês
                                                                                </small>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                                                    id="recurrence_monthly_day"
                                                                                    name="recurrence_monthly_day"
                                                                                    min={1}
                                                                                    max={31}
                                                                                    value={this.state.fields.recurrence_monthly_day}
                                                                                    onChange={this.handleChange}
                                                                                />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                <div className="col-12 col-md-3">
                                                                    <div className="form-group">
                                                                        <label className="d-block"
                                                                               htmlFor="recurrence_end_times">
                                                                            <small id="recurrence_end_timeslabel"
                                                                                   className="form-text text-muted mb-2">
                                                                                Última recorrência
                                                                            </small>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                                                id="data_final"
                                                                                name="data_final"
                                                                                value={this.state.fields.data_final}
                                                                                onChange={this.handleChange}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cursos">
                                            <small id="cursoslabel" className="form-text text-muted mb-2">
                                                Cursos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="cursosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"cursosReturn"}>
                                                                {this.state.cursosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar cursos"
                                                            onKeyUp={this.searchCursos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/meetings"}
                                  onClick={this.cancelarReuniao}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.criarReuniao}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Criar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MeetingsCadastroView;