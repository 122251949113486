import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class CombosCadastroEditarView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            id: props.match.params.id,
            fields: {
                codigo: "",
                nome: "",
                observacoes: "",
                cursos: []
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()

        if (
            this.state.fields.codigo.length <= 0 ||
            this.state.fields.nome.length <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o codigo e nome estão preenchidos corretamente."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let cursosArr = []

            jQuery('#cursosContainer option').each((index, item) => {
                if (jQuery(item).is(':selected')) {
                    cursosArr.push(jQuery(item).val())
                }
            })

            let saveData = {
                codigo: this.state.fields.codigo,
                nome: this.state.fields.nome,
                observacoes: this.state.fields.observacoes,
                cursos: cursosArr
            }

            Axios.patch(
                "https://servicecombo.the-cyberpunk.com/combo/" + this.state.id,
                saveData
            )
                .then((responseData) => {
                    SweetAlert({
                        title: "Tudo certo!",
                        text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                        .then((response) => {
                            if (response) {
                                window.location.href = '/admin/combos'
                            }
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    searchCursos = (event) => {
        let {name, value} = event.target
        jQuery('#cursosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    async componentDidMount() {
        let combo = await Axios.get('https://servicecombo.the-cyberpunk.com/combo/' + this.state.id)
        let comboData = combo.data
        let cursosData = await Axios.get('https://servicecurso.the-cyberpunk.com/cursos')

        await this.setState({
            fields: {
                codigo: comboData.codigo,
                nome: comboData.nome,
                observacoes: comboData.observacoes,
                cursos: comboData.cursos
            }
        })

        let cursosList = []
        cursosData.data.map((item, index) => {
            let selected = this.state.fields.cursos.indexOf(item._id) >= 0
            cursosList.push(
                <option
                    selected={selected}
                    key={index}
                    value={item._id}
                    className="btn btn-light text-left w-100"
                >
                    {item.codigo} - {item.nome}
                </option>
            )
        })

        await this.setState({
            cursosOptions: cursosList,
            done: true
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="combos-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Cadastro de novo
                    Combo</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="codigo">
                                            <small id="namelabel" className="form-text text-muted mb-2">
                                                Código do Combo
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="codigo"
                                                name="codigo"
                                                value={this.state.fields.codigo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="nomelabel" className="form-text text-muted mb-2">
                                                Nome do Combo
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="observacoes">
                                            <small id="observacoeslabel" className="form-text text-muted mb-2">
                                                Observações
                                            </small>
                                            <textarea
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="observacoes"
                                                name="observacoes"
                                                rows={5}
                                                value={this.state.fields.observacoes}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cursos">
                                            <small id="cursoslabel" className="form-text text-muted mb-2">
                                                Cursos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="cursosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"cursosReturn"}>
                                                                {this.state.cursosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar cursos"
                                                            onKeyUp={this.searchCursos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/combos"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CombosCadastroEditarView;