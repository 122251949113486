import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import {Editor} from '@tinymce/tinymce-react'
import {Accordion, Button, Card} from "react-bootstrap"
import QuestaoAlternativa from "../../components/ModuloQuestaoAlernativa"
import ModuloEscolhaTipoQuestao from "../../components/ModuloEscolhaTipoQuestao"

class AtividadesCadastroEditarView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            done: false,
            id: props.match.params.id,
            universalQuestoesTipo: props.match.params.tipo,
            universalQuestoesCount: 1,
            defaultActiveKeyQuestions: 0,
            questoesContainer: [],
            questoes: [],
            modulos: [],
            questionType: null,
            fields: {
                titulo: "",
                data_resultado: "",
                questoes: [],
                cursos: [],
                professores: [],
            }
        }
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = async (event) => {
        event.preventDefault()

        let professoresArr = []
        let cursosArr = []
        let cursosObj = []
        let questoesArr = []

        jQuery('#professoresContainer option').each((index, item) => {
            if (jQuery(item).is(':selected')) {
                professoresArr.push(jQuery(item).val())
            }
        })

        jQuery('#cursosContainer option').each((index, item) => {
            if (jQuery(item).is(':selected')) {
                cursosArr.push(jQuery(item).val())
            }
        })

        let validaQuestionario = true
        jQuery('#questoesContainer .question-group').each((index, item) => {
            let key = jQuery(item).data('key')

            let titulo = jQuery(item).find('#titulo').val()
            let peso = jQuery(item).find('#peso').val()
            let enunciado = this.state.fields.questoes['enunciado[' + key + ']'] ? this.state.fields.questoes['enunciado[' + key + ']'] : ""

            let alternativas = []

            jQuery(item).find('.alternativas-group .alternativa-item').each((j, alternativa) => {
                let key = jQuery(alternativa).data('key')
                let alternativa_q = jQuery(alternativa).find('[name="alternativa_' + key + '"]').is(':checked')
                let titulo_q = jQuery(alternativa).find('[name="titulo_' + key + '"]').val()

                if (
                    alternativa_q.length <= 0 ||
                    titulo_q.length <= 0
                ) {
                    validaQuestionario = false
                } else {
                    alternativas.push({
                        correta: alternativa_q,
                        titulo: titulo_q
                    })
                }
            })

            if (
                titulo.length <= 0 ||
                peso.length <= 0 ||
                enunciado.length <= 0
            ) {
                validaQuestionario = false
            } else {
                questoesArr.push({
                    titulo: titulo,
                    peso: peso,
                    enunciado: enunciado,
                    alternativas: alternativas
                })
            }
        })

        if (!validaQuestionario) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se todos os campos do questionário estão devidamente preenchidos."
            )
        } else if (
            this.state.fields.titulo.length <= 0 ||
            cursosArr.length <= 0 ||
            questoesArr.length <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se o titulo, data de início, data de término, data do resultado, módulos e questões estão preenchidas corretamente."
            )
        } else {
            SweetAlert({
                title: "Salvando atividade",
                text: "Processando as informações do cadastro da atividade"
            })

            for (let c in cursosArr) {
                let modulos = []
                jQuery('#modulosContainer optgroup[data-curso="' + cursosArr[c] + '"] option:selected').each((i, item) => {
                    modulos.push({
                        id: jQuery(item).val(),
                        titulo: jQuery(item).text()
                    })
                })

                cursosObj.push({
                    curso: cursosArr[c],
                    modulos: modulos
                })
            }

            let atividadeSave = {
                titulo: this.state.fields.titulo,
                tipo_atividade: this.state.universalQuestoesTipo,
                questoes: questoesArr,
                professores: professoresArr,
                cursos: cursosObj,
                data_resultado: this.state.fields.data_resultado,
            }

            Axios.patch(
                "https://serviceatividade.the-cyberpunk.com/atividade/" + this.state.id,
                atividadeSave
            )
                .then(async (responseData) => {
                    SweetAlert({
                        title: "Tudo certo!",
                        text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                        .then((response) => {
                            if (response) {
                                window.location.href = '/admin/atividades'
                            }
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Entre em contato com o mantenedor do sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    handleQuestaoChange = async (event, countQuest) => {
        let {name, type, value} = event.target

        jQuery('#questoesContainer .question-group[data-key="' + countQuest + '"]').find('input[name="' + name + '"]').val(value)
    }

    handleQuestaoChangeEnunciado = (conteudo, eventKey) => {
        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                questoes: {
                    ...prevState.fields.questoes,
                    ["enunciado[" + eventKey + "]"]: conteudo
                }
            }
        }))
    }

    removeQuestion = async (questionId) => {
        let questoes = this.state.questoes

        questoes.map((item, i) => {
            console.log(item.key, questionId)
            if (item.key == questionId) {
                questoes.splice(i, 1)
            }
        })

        let container = <Accordion>{questoes}</Accordion>

        await this.setState({
            questoesContainer: container,
            questoes: questoes
        })
    }

    setDefaultActiveKeyQuestions = (activeKey) => {
        let container = <Accordion activeKey={activeKey}>{this.state.questoes}</Accordion>

        this.setState({
            questoesContainer: container,
            defaultActiveKeyQuestions: activeKey
        })
    }

    addQuestao = async (event) => {
        let tipoQuestao = this.state.universalQuestoesTipo
        let questoes = this.state.questoes
        let o = null
        let activeKey = questoes.length ? questoes.length + 1 : 1
        let countQuest = this.state.universalQuestoesCount

        await this.setState({
            defaultActiveKeyQuestions: countQuest
        })

        if (
            tipoQuestao === "questionario_simples" ||
            tipoQuestao === "simulado"
        ) {
            o =
                <Card key={countQuest}>
                    <Card.Header className={"d-flex"}>
                        <Accordion.Toggle eventKey={countQuest} as={Button} className={"text-white"}
                                          onClick={() => this.setDefaultActiveKeyQuestions(countQuest)}>
                            Questão {activeKey}
                        </Accordion.Toggle>
                        <Button className={"btn btn-danger ml-auto"} onClick={() => this.removeQuestion(countQuest)}>
                            <i className="fa fa-times"></i>
                        </Button>
                    </Card.Header>
                    <Accordion.Collapse eventKey={countQuest}>
                        <Card.Body>
                            <div className="row align-items-start justify-content-start question-group"
                                 data-key={countQuest}>
                                <div className="col-12 col-md-9">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="titulo">
                                            <small id="titulolabel" className="form-text text-muted mb-2">
                                                Título da Questão
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="titulo"
                                                name={"titulo"}
                                                onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="peso">
                                            <small id="pesolabel" className="form-text text-muted mb-2">
                                                Peso
                                            </small>
                                            <input
                                                type="number"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="peso"
                                                name={"peso"}
                                                min={0}
                                                max={10}
                                                step={0.25}
                                                onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="enunciado">
                                            <small id="enunciadolabel" className="form-text text-muted mb-2">
                                                Enunciado
                                            </small>
                                            <Editor
                                                apiKey="1nvcxl5he6ohh3urm1qfdihnhmwi0jqxgaxtktuoiunn05eg"
                                                init={{
                                                    height: 300,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help'
                                                }}
                                                onEditorChange={(event) => this.handleQuestaoChangeEnunciado(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 alternativas-group">
                                    <QuestaoAlternativa counter={countQuest}/>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
        }

        if (
            tipoQuestao === "questionario_complexo" ||
            tipoQuestao === "prova"
        ) {
            o =
                <Card key={countQuest}>
                    <Card.Header className={"d-flex"}>
                        <Accordion.Toggle eventKey={countQuest} as={Button} className={"text-white"}
                                          onClick={() => this.setDefaultActiveKeyQuestions(countQuest)}>
                            Questão {activeKey}
                        </Accordion.Toggle>
                        <Button className={"btn btn-danger ml-auto"} onClick={() => this.removeQuestion(countQuest)}>
                            <i className="fa fa-times"></i>
                        </Button>
                    </Card.Header>
                    <Accordion.Collapse eventKey={countQuest}>
                        <Card.Body>
                            <div className="row align-items-start justify-content-start question-group"
                                 data-key={countQuest}>
                                <div className="col-12 col-md-9">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="titulo">
                                            <small id="titulolabel" className="form-text text-muted mb-2">
                                                Título da Questão
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="titulo"
                                                name={"titulo"}
                                                onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="peso">
                                            <small id="pesolabel" className="form-text text-muted mb-2">
                                                Peso
                                            </small>
                                            <input
                                                type="number"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="peso"
                                                name={"peso"}
                                                min={0}
                                                max={10}
                                                step={0.25}
                                                onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="enunciado">
                                            <small id="enunciadolabel" className="form-text text-muted mb-2">
                                                Enunciado
                                            </small>
                                            <Editor
                                                apiKey="1nvcxl5he6ohh3urm1qfdihnhmwi0jqxgaxtktuoiunn05eg"
                                                init={{
                                                    height: 300,
                                                    menubar: true,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic backcolor | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat | help'
                                                }}
                                                onEditorChange={(event) => this.handleQuestaoChangeEnunciado(event, countQuest)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 alternativas-group">
                                    <ModuloEscolhaTipoQuestao counter={countQuest}/>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
        }

        questoes.push(o)
        let container = <Accordion activeKey={this.state.defaultActiveKeyQuestions}>{questoes}</Accordion>

        await this.setState({
            questoesContainer: container,
            questoes: questoes,
            universalQuestoesCount: countQuest + 1
        })
    }

    searchCursos = (event) => {
        let {name, value} = event.target
        jQuery('#cursosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    searchModulos = (event) => {
        let {name, value} = event.target
        jQuery('#modulosContainer option').each((index, item) => {
            if (!item.innerHTML.toLowerCase().includes(value.toLowerCase())) {
                jQuery(item).addClass("d-none")
            } else {
                jQuery(item).removeClass("d-none")
            }
        })
    }

    mountModulos = async (event) => {
        jQuery("#modulosReturn").val("")

        let cursosIDs = jQuery('#cursosReturn').val()
        let o = []
        for (let c in cursosIDs) {
            let cursoInfo = await Axios.get('https://servicecurso.the-cyberpunk.com/curso/' + cursosIDs[c])
            let modulos = []

            for (let m in cursoInfo.data.modulos) {
                modulos.push(
                    <option
                        value={cursoInfo.data.modulos[m].id}
                        className={"btn btn-light text-left w-100"}
                    >
                        {cursoInfo.data.modulos[m].nome}
                    </option>
                )
            }

            o.push(
                <optgroup
                    data-curso={cursosIDs[c]}
                    label={event.target.selectedOptions[c].label}
                    className={"border-bottom d-flex flex-wrap px-2"}
                >
                    {modulos}
                </optgroup>
            )
        }

        this.setState({
            modulosOptions: o
        })
    }

    async componentDidMount() {
        let atividadeData = await Axios.get('https://serviceatividade.the-cyberpunk.com/atividade/' + this.state.id)
        let cursosData = await Axios.get('https://servicecurso.the-cyberpunk.com/cursos')
        let professoresData = await Axios.get('https://serviceperfil.the-cyberpunk.com/professores')

        let cursosListData = atividadeData.data.cursos.map((a) => a.curso)

        let cursosList = []
        for (let c in cursosData.data) {
            let selected = cursosListData.indexOf(cursosData.data[c]._id) >= 0
            cursosList.push(
                <option
                    key={c}
                    selected={selected}
                    value={cursosData.data[c]._id}
                    className="btn btn-light text-left w-100"
                >
                    {cursosData.data[c].codigo} - {cursosData.data[c].nome}
                </option>
            )
        }

        let professoresList = []
        for (let p in professoresData.data) {
            let selected = atividadeData.data.professores.indexOf(professoresData.data[p]._id) >= 0
            professoresList.push(
                <option
                    key={p}
                    selected={selected}
                    value={professoresData.data[p]._id}
                    className="btn btn-light text-left w-100"
                >
                    {professoresData.data[p].nome}
                </option>
            )
        }

        let o = []
        for (let c in cursosListData) {
            let cursoInfo = await Axios.get('https://servicecurso.the-cyberpunk.com/curso/' + cursosListData[c])
            let modulos = []

            let cursosModulosData = []
            atividadeData.data.cursos.map((a) => {
                if (a.curso === cursosListData[c]) {
                    cursosModulosData = a.modulos
                }
            })

            for (let m in cursoInfo.data.modulos) {
                let selected = false
                cursosModulosData.map((c) => {
                    if (c.id === cursoInfo.data.modulos[m].id) {
                        selected = true
                    }
                })

                modulos.push(
                    <option
                        selected={selected}
                        value={cursoInfo.data.modulos[m].id}
                        className={"btn btn-light text-left w-100"}
                    >
                        {cursoInfo.data.modulos[m].nome}
                    </option>
                )
            }

            let cursoNome = ""
            cursosData.data.map((i) => {
                if (i._id === cursosListData[c]) {
                    cursoNome = i.nome
                }
            })

            o.push(
                <optgroup
                    data-curso={cursosListData[c]}
                    label={cursoNome}
                    className={"border-bottom d-flex flex-wrap px-2"}
                >
                    {modulos}
                </optgroup>
            )
        }

        let tipoQuestao = atividadeData.data.tipo_atividade
        let questoes = []

        await Promise.all(
            atividadeData.data.questoes.map((questaoItem, countQuest) => {
                let activeKey = countQuest + 1
                let o = null
                countQuest = activeKey

                if (
                    tipoQuestao === "questionario_simples" ||
                    tipoQuestao === "simulado"
                ) {
                    o =
                        <Card key={countQuest}>
                            <Card.Header className={"d-flex"}>
                                <Accordion.Toggle eventKey={countQuest} as={Button} className={"text-white"}
                                                  onClick={() => this.setDefaultActiveKeyQuestions(countQuest)}>
                                    Questão {activeKey}
                                </Accordion.Toggle>
                                <Button className={"btn btn-danger ml-auto"}
                                        onClick={() => this.removeQuestion(countQuest)}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Card.Header>
                            <Accordion.Collapse eventKey={countQuest}>
                                <Card.Body>
                                    <div className="row align-items-start justify-content-start question-group"
                                         data-key={countQuest}>
                                        <div className="col-12 col-md-9">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="titulo">
                                                    <small id="titulolabel" className="form-text text-muted mb-2">
                                                        Título da Questão
                                                    </small>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="titulo"
                                                        name={"titulo"}
                                                        defaultValue={questaoItem.titulo}
                                                        onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="peso">
                                                    <small id="pesolabel" className="form-text text-muted mb-2">
                                                        Peso
                                                    </small>
                                                    <input
                                                        type="number"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="peso"
                                                        name={"peso"}
                                                        min={0}
                                                        max={10}
                                                        step={0.25}
                                                        defaultValue={questaoItem.peso}
                                                        onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="enunciado">
                                                    <small id="enunciadolabel" className="form-text text-muted mb-2">
                                                        Enunciado
                                                    </small>
                                                    <Editor
                                                        apiKey="1nvcxl5he6ohh3urm1qfdihnhmwi0jqxgaxtktuoiunn05eg"
                                                        initialValue={questaoItem.enunciado}
                                                        init={{
                                                            height: 300,
                                                            menubar: true,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                'undo redo | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | help'
                                                        }}
                                                        onEditorChange={(event) => this.handleQuestaoChangeEnunciado(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 alternativas-group">
                                            <QuestaoAlternativa
                                                counter={countQuest}
                                                mounted={questaoItem.alternativas}
                                                i={countQuest}
                                            />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                }

                if (
                    tipoQuestao === "questionario_complexo" ||
                    tipoQuestao === "prova"
                ) {
                    o =
                        <Card key={countQuest}>
                            <Card.Header className={"d-flex"}>
                                <Accordion.Toggle eventKey={countQuest} as={Button} className={"text-white"}
                                                  onClick={() => this.setDefaultActiveKeyQuestions(countQuest)}>
                                    Questão {activeKey}
                                </Accordion.Toggle>
                                <Button className={"btn btn-danger ml-auto"}
                                        onClick={() => this.removeQuestion(countQuest)}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Card.Header>
                            <Accordion.Collapse eventKey={countQuest}>
                                <Card.Body>
                                    <div className="row align-items-start justify-content-start question-group"
                                         data-key={countQuest}>
                                        <div className="col-12 col-md-9">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="titulo">
                                                    <small id="titulolabel" className="form-text text-muted mb-2">
                                                        Título da Questão
                                                    </small>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="titulo"
                                                        name={"titulo"}
                                                        defaultValue={questaoItem.titulo}
                                                        onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="peso">
                                                    <small id="pesolabel" className="form-text text-muted mb-2">
                                                        Peso
                                                    </small>
                                                    <input
                                                        type="number"
                                                        className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                        id="peso"
                                                        name={"peso"}
                                                        min={0}
                                                        max={10}
                                                        step={0.25}
                                                        defaultValue={questaoItem.peso}
                                                        onChange={(event) => this.handleQuestaoChange(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="enunciado">
                                                    <small id="enunciadolabel" className="form-text text-muted mb-2">
                                                        Enunciado
                                                    </small>
                                                    <Editor
                                                        apiKey="1nvcxl5he6ohh3urm1qfdihnhmwi0jqxgaxtktuoiunn05eg"
                                                        initialValue={questaoItem.enunciado}
                                                        init={{
                                                            height: 300,
                                                            menubar: true,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar:
                                                                'undo redo | formatselect | bold italic backcolor | \
                                                                alignleft aligncenter alignright alignjustify | \
                                                                bullist numlist outdent indent | removeformat | help'
                                                        }}
                                                        onEditorChange={(event) => this.handleQuestaoChangeEnunciado(event, countQuest)}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 alternativas-group">
                                            <ModuloEscolhaTipoQuestao counter={countQuest}/>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                }

                questoes.push(o)
            })
        )

        await this.setState({
            done: true,
            universalQuestoesTipo: atividadeData.data.tipo_atividade,
            fields: atividadeData.data,
            cursosOptions: cursosList,
            modulosOptions: o,
            professoresOptions: professoresList,
            questoes: questoes,
            questoesContainer: <Accordion>{questoes}</Accordion>,
            universalQuestoesCount: questoes.length + 1
        })
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="atividades-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">
                    Cadastro de nova Atividade
                </h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div
                                    className={"col-12 " + ((this.state.universalQuestoesTipo !== "simulado" && this.state.universalQuestoesTipo !== "questionario_simples" ? "col-md-5" : "col-md-8"))}>
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="titulo">
                                            <small id="titulolabel" className="form-text text-muted mb-2">
                                                Título da Atividade
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="titulo"
                                                name="titulo"
                                                value={this.state.fields.titulo}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="professores">
                                            <small id="professoreslabel" className="form-text text-muted mb-2">
                                                Professor responsável
                                            </small>
                                            <div id="professoresContainer" className={"rounded"}>
                                                <select
                                                    className={"custom-select rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"}
                                                    disabled={localStorage.getItem('userRole') === "professor"}
                                                    id={"professoresReturn"}
                                                    name={"professoresReturn"}>
                                                    <option value="">Selecione</option>
                                                    {this.state.professoresOptions}
                                                </select>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                {(this.state.universalQuestoesTipo !== "simulado") && (this.state.universalQuestoesTipo !== "questionario_simples") ? (
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label className="d-block" htmlFor="data_resultado">
                                                <small id="data_resultadolabel" className="form-text text-muted mb-2">
                                                    Data de Divulgação da Nota
                                                </small>
                                                <input
                                                    type="date"
                                                    className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                    id="data_resultado"
                                                    name="data_resultado"
                                                    value={this.state.fields.data_resultado}
                                                    onChange={this.handleChange}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cursos">
                                            <small id="cursoslabel" className="form-text text-muted mb-2">
                                                Cursos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="cursosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    onChange={this.mountModulos}
                                                                    name={"cursosReturn"}
                                                                    id={"cursosReturn"}
                                                            >
                                                                {this.state.cursosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar cursos"
                                                            onKeyUp={this.searchCursos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="modulos">
                                            <small id="moduloslabel" className="form-text text-muted mb-2">
                                                Módulos
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block text-right">
                                                        <div id="modulosContainer" className={"rounded"}>
                                                            <select multiple
                                                                    className={"form-control custom-select p-0 d-flex flex-column"}
                                                                    name={"modulosReturn"}
                                                                    id={"modulosReturn"}
                                                            >
                                                                {this.state.modulosOptions}
                                                            </select>
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Pesquisar módulos"
                                                            onKeyUp={this.searchModulos}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="questoes">
                                            <small id="questoeslabel" className="form-text text-muted mb-2">
                                                Questões
                                            </small>
                                            <div className="card">
                                                <div className="card-body p-3">
                                                    <div className="d-block">
                                                        <div id="questoesContainer" className={"rounded"}>
                                                            {this.state.questoesContainer}
                                                        </div>
                                                        <hr className="my-3"/>
                                                        <button onClick={this.addQuestao}
                                                                className="btn btn-primary text-white">
                                                            Adicionar questão
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/atividades"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AtividadesCadastroEditarView;