import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import DataTable from 'react-data-table-component'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom";

class AtividadesView extends Component {
    constructor(props) {
        super(props);
        const Scope = this;

        this.state = {
            dadosAtividades: [],
            done: false,
            TableColumns: [
                {
                    name: "Título da Atividade",
                    selector: "titulo",
                    sortable: true,
                    width: "50%"
                },
                {
                    name: "Status",
                    selector: "status",
                    width: "10%",
                    cell: (row) => (
                        <div className={"d-inline-block"}>
                            {row.status ? 'Ativo' : 'Inativo'}
                        </div>
                    ),
                    sortable: true
                },
                {
                    name: "Ações",
                    width: "40%",
                    cell: (row) => (
                        <div className="d-flex align-items-center">
                            <button onClick={() => Scope.dispararNotificacoes(row)}
                                    className="btn btn-warning btn-sm mr-1"
                            >
                                Notificar alunos
                            </button>
                            <Link to={"/admin/atividades/editar/" + row._id}
                                  className="btn btn-primary text-white btn-sm mr-1">Editar
                            </Link>
                            <button onClick={() => Scope.deletarAtividade(row._id)}
                                    className="btn btn-danger btn-sm mr-1">Excluir
                            </button>
                            {row.status == 1 ? (
                                <button onClick={() => Scope.desativarAtividade(row._id)}
                                        className="btn btn-secondary btn-sm">Desativar
                                </button>
                            ) : (
                                <button onClick={() => Scope.ativarAtividade(row._id)}
                                        className="btn btn-success text-white btn-sm">Ativar
                                </button>
                            )}
                        </div>
                    )
                }
            ]
        }
    }

    dispararNotificacoes = (atividadeData) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza que quer notificar todos os alunos envolvidos?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-la']
        })
            .then(async (response) => {
                if (response) {
                    SweetAlert({
                        title: "Um momento",
                        text: "Criando a notificação"
                    })

                    let cursosArr = atividadeData.cursos.map((a) => a.curso)

                    let tituloNotificacao = ""
                    switch (atividadeData.tipoAtividade) {
                        case "questionario_simples":
                            tituloNotificacao = "Nova atividade"
                            break

                        case "simulado":
                            tituloNotificacao = "Novo simulado"
                            break

                        case "questionario_complexo":
                            tituloNotificacao = "Nova atividade"
                            break

                        case "prova":
                            tituloNotificacao = "Nova prova"
                            break

                        default:
                            tituloNotificacao = "Nova notificação"
                            break
                    }

                    let notificacoesArr = []
                    for (let c in cursosArr) {
                        await Axios.post('https://servicenotificacao.the-cyberpunk.com/notificacao', {
                            tipo: "atividade",
                            titulo: tituloNotificacao,
                            descricao: atividadeData.titulo,
                            link: "/atividade/" + atividadeData._id,
                            duracao: 30
                        })
                            .then((notificacaoData) => {
                                notificacoesArr.push(notificacaoData.data._id)
                            })
                    }

                    SweetAlert({
                        title: "Salvando atividade",
                        text: "Disparando notificações para os alunos"
                    })

                    let alunosArr = []
                    for (let c in cursosArr) {
                        let responseDataCursos = await Axios.post('https://servicecurso.the-cyberpunk.com/curso-alunos/' + cursosArr[c])
                        responseDataCursos.data.map((aluno, a) => {
                            if (alunosArr.indexOf(aluno) < 0) {
                                alunosArr.push(aluno)
                            }
                        })
                    }

                    for (let a in alunosArr) {
                        await Axios.patch('https://serviceperfil.the-cyberpunk.com/aluno/' + alunosArr[a], {
                            $push: {
                                notificacoes: notificacoesArr
                            }
                        })
                    }

                    SweetAlert({
                        title: "Tudo certo!",
                        text: "Notificações enviadas.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                }
            })
    }

    ativarAtividade = (AtividadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer ativar esta atividade?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero ativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceatividade.the-cyberpunk.com/atividade/' + AtividadeID,
                        {status: 1}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Atividade ativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    desativarAtividade = (AtividadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer desativar esta atividade?',
            icon: 'info',
            buttons: ['Cancelar', 'Sim, quero desativá-la']
        })
            .then((response) => {
                if (response) {
                    Axios.patch(
                        'https://serviceatividade.the-cyberpunk.com/atividade/' + AtividadeID,
                        {status: 0}
                    )
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Atividade desativada.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    deletarAtividade = (AtividadeID) => {
        SweetAlert({
            title: 'Cuidado!',
            text: 'Tem certeza de que quer excluir esta atividade?',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancelar', 'Sim, quero excluir']
        })
            .then((response) => {
                if (response) {
                    Axios.delete('https://serviceatividade.the-cyberpunk.com/atividade/' + AtividadeID)
                        .then(() => {
                            SweetAlert(
                                'Pronto!',
                                'Atividade excluída.',
                                'success'
                            ).then(() => {
                                window.location.reload()
                            })
                        })
                }
            })
    }

    loadAtividades = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://serviceatividade.the-cyberpunk.com/atividades').then((responseData) => {
            this.setState({
                dadosAtividades: responseData.data,
                done: true
            })
        })
    }

    async componentDidMount() {
        await this.loadAtividades()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="atividades-listagem" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Atividades</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <DataTable
                            columns={this.state.TableColumns}
                            data={this.state.dadosAtividades}
                            noDataComponent={"Nenhum registro encontrado"}
                            pagination
                            striped
                            defaultSortField="titulo"
                        />
                        <hr className="mb-4 mt-5"/>
                        <div className="row align-items-stretch">
                            <div className="col-12 col-md-3 mb-4 mb-md-0">
                                <Link to={"/admin/atividades/nova/questionario_simples"}
                                      className="btn border-radius py-2 text-center bg-primary text-white h-100 w-100 d-flex align-items-center justify-content-center"
                                >
                                    Novo Questionário Simples
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-4 mb-md-0">
                                <Link to={"/admin/atividades/nova/questionario_complexo"}
                                      className="btn border-radius py-2 text-center bg-primary text-white h-100 w-100 d-flex align-items-center justify-content-center"
                                >
                                    Novo Questionário Complexo
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-4 mb-md-0">
                                <Link to={"/admin/atividades/nova/simulado"}
                                      className="btn border-radius py-2 text-center bg-primary text-white h-100 w-100 d-flex align-items-center justify-content-center"
                                >
                                    Novo Simulado
                                </Link>
                            </div>
                            <div className="col-12 col-md-3 mb-4 mb-md-0">
                                <Link to={"/admin/atividades/nova/prova"}
                                      className="btn border-radius py-2 text-center bg-primary text-white h-100 w-100 d-flex align-items-center justify-content-center"
                                >
                                    Nova Prova
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AtividadesView;