import React, {Component} from "react"
import Axios from "axios"
import jQuery from 'jquery'
import SweetAlert from 'sweetalert'
import ReactLoading from "react-loading"
import {Link} from "react-router-dom"
import MaskedInput from "react-text-mask"

class PerfisCoordenadoresCadastroEditarView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            done: false,
            id: props.match.params.id
        }
    }

    loadPerfis = async () => {
        this.setState({
            done: false
        })

        await Axios.get('https://serviceperfil.the-cyberpunk.com/coordenador/' + this.state.id)
            .then((responseData) => {
                let dados = responseData.data
                this.setState({
                    fields: {
                        email: dados.email,
                        nome: dados.nome,
                        // escolaridade: dados.//,
                        telefone: dados.telefones[0],
                        whatsapp: dados.telefones[1],
                        cpf: dados.documentos.cpf,
                        rg: dados.documentos.rg,
                        foto: "",
                        senha: "",
                        senhaConfirm: "",
                        nascimento: dados.nascimento,
                        cidade: dados.endereco.cidade,
                        estado: dados.endereco.estado,
                        uf: dados.endereco.uf,
                        pais: dados.endereco.pais,
                        cep: dados.endereco.cep,
                        logradouro: dados.endereco.logradouro,
                        bairro: dados.endereco.bairro,
                        numero: dados.endereco.numero,
                        complemento: dados.endereco.complemento
                    },
                    done: true
                })
            })
    }

    cancelarCadastro = (event) => {
        event.preventDefault()

        SweetAlert({
            title: "Atenção!",
            text: "Tem certeza de que deseja cancelar? As informações digitadas serão perdidas!",
            icon: "error",
            dangerMode: true,
            buttons: {
                cancel: "Mudei de idéia",
                confirm: "Sim, quero cancelar"
            }
        })
            .then((response) => {
                if (response) {
                    window.location.href = event.target.href
                }
            })
    }

    salvarCadastro = (event) => {
        event.preventDefault()

        if (this.state.fields.senha !== this.state.fields.senhaConfirm) {
            SweetAlert(
                "Erro",
                "A senha e a confirmação da senha são diferentes."
            )
        } else if (
            this.state.fields.senha.length < 12 &&
            this.state.fields.senha.length > 0
        ) {
            SweetAlert(
                "Erro",
                "A sua senha precisa ter no mínimo 12 caracteres para ser considerada segura."
            )
        } else if (
            this.state.fields.nome.length <= 0 ||
            this.state.fields.telefone.length <= 0 ||
            this.state.fields.whatsapp.length <= 0 ||
            this.state.fields.rg.length <= 0 ||
            this.state.fields.cpf.length <= 0
        ) {
            SweetAlert(
                "Erro",
                "Por favor, verifique se nome, telefone, whatsapp, rg, cpf e senha estão preenchidos corretamente."
            )
        } else if (!this.validaCPF(this.state.fields.cpf)) {
            SweetAlert(
                "Erro",
                "O CPF digitado está incorreto. Por favor, verifique o número digitado."
            )
        } else {
            SweetAlert({
                text: "Tudo certo! Agora vamos salvar o cadastro.",
                button: {
                    text: "Ok, vou aguardar"
                }
            })

            let saveData = {
                nome: this.state.fields.nome,
                endereco: {
                    cidade: this.state.fields.cidade,
                    estado: this.state.fields.estado,
                    uf: this.state.fields.uf,
                    pais: this.state.fields.pais,
                    cep: this.state.fields.cep,
                    logradouro: this.state.fields.logradouro,
                    bairro: this.state.fields.bairro,
                    numero: this.state.fields.numero,
                    complemento: this.state.fields.complemento
                },
                // escolaridade: {},
                telefones: [
                    this.state.fields.telefone,
                    this.state.fields.whatsapp
                ],
                documentos: {
                    cpf: this.state.fields.cpf,
                    rg: this.state.fields.rg
                },
                foto: "",
                nascimento: this.state.fields.nascimento
            }

            if (this.state.fields.senha.length > 0) {
                saveData['senha'] = this.state.fields.senha
            }

            Axios.patch(
                "https://serviceperfil.the-cyberpunk.com/coordenador/" + this.state.id,
                saveData
            )
                .then((responseData) => {
                    SweetAlert({
                        title: "Tudo certo!",
                        text: "O cadastro foi salvo com sucesso. Você voltará para a listagem.",
                        icon: "success",
                        button: {
                            text: "Legal!"
                        }
                    })
                        .then((response) => {
                            if (response) {
                                window.location.href = '/admin/coordenadores'
                            }
                        })
                })
                .catch((err) => {
                    SweetAlert({
                        title: "Erro",
                        text: "Não foi possível finalizar o cadastro. Verifique se este e-mail é único em todo o sistema.",
                        icon: 'error'
                    })
                })
        }
    }

    validaCPF(cpf) {
        if (typeof cpf !== "string") {
            return false
        }
        cpf = cpf.replace(/[\s.-]*/igm, '')

        if (
            !cpf ||
            cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999"
        ) {
            return false
        }

        let soma = 0
        let resto

        for (let i = 1; i <= 9; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        }

        resto = (soma * 10) % 11
        if ((resto == 10) || (resto == 11)) {
            resto = 0
        }

        if (resto != parseInt(cpf.substring(9, 10))) {
            return false
        }

        soma = 0
        for (let i = 1; i <= 10; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
        }

        resto = (soma * 10) % 11

        if ((resto == 10) || (resto == 11)) {
            resto = 0
        }

        if (resto != parseInt(cpf.substring(10, 11))) {
            return false
        }

        return true
    }

    handleChange = (event) => {
        let {name, type, value} = event.target

        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value
            }
        }))
    }

    autoCompleteAddress = (event) => {
        let cep = event.target.value
        let that = this

        cep = cep.replace('/[^0-9]/', '')

        let uf = {
            "AC": "Acre",
            "AL": "Alagoas",
            "AP": "Amapá",
            "AM": "Amazonas",
            "BA": "Bahia",
            "CE": "Ceará",
            "ES": "Espírito Santo",
            "GO": "Goiás",
            "MA": "Maranhão",
            "MT": "Mato Grosso",
            "MS": "Mato Grosso do Sul",
            "MG": "Minas Gerais",
            "PA": "Pará",
            "PB": "Paraíba",
            "PR": "Paraná",
            "PE": "Pernambuco",
            "PI": "Piauí",
            "RJ": "Rio de Janeiro",
            "RN": "Rio Grande do Norte",
            "RS": "Rio Grande do Sul",
            "RO": "Rondônia",
            "RR": "Roraima",
            "SC": "Santa Catarina",
            "SP": "São Paulo",
            "SE": "Sergipe",
            "TO": "Tocantins",
            "DF": "Distrito Federal"
        }

        jQuery.get('https://viacep.com.br/ws/' + cep + '/json/ ').always(function (returnData) {

            if (returnData.logradouro &&
                returnData.bairro &&
                returnData.localidade &&
                returnData.uf
            ) {
                that.setState(prevState => ({
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        logradouro: returnData.logradouro,
                        bairro: returnData.bairro,
                        cidade: returnData.localidade,
                        uf: returnData.uf.toUpperCase(),
                        estado: uf[returnData.uf.toUpperCase()],
                        pais: "Brasil"
                    }
                }))
            }
        })
    }

    async componentDidMount() {
        await this.loadPerfis()
    }

    render() {
        return !this.state.done ? (
            <div className="d-flex align-items-center justify-content-center h-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img src={process.env.PUBLIC_URL + '/images/logo192.png'}
                         className={"mb-2"}
                         style={{
                             maxHeight: "50px"
                         }}/>
                    <ReactLoading type={"bars"} color={"#37c1d5ff"}/>
                </div>
            </div>
        ) : (
            <div id="perfis-coordenadores-cadastro" className="px-3 px-md-5 my-5">
                <h6 className="text-center font-weight-bold mb-3 text-md-left text-uppercase">Edição de
                    Coordenador</h6>
                <div className="card">
                    <div className="card-body p-4">
                        <div className="d-block">
                            <div className="row align-items-start justify-content-start">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nome">
                                            <small id="namelabel" className="form-text text-muted mb-2">
                                                Nome completo
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nome"
                                                name="nome"
                                                value={this.state.fields.nome}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="email">
                                            <small id="emaillabel" className="form-text text-muted mb-2">
                                                E-mail
                                            </small>
                                            <input
                                                type="email"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="email"
                                                name="email"
                                                disabled
                                                value={this.state.fields.email}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cpf">
                                            <small id="cpflabel" className="form-text text-muted mb-2">
                                                CPF
                                            </small>
                                            <MaskedInput
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="cpf"
                                                name="cpf"
                                                value={this.state.fields.cpf}
                                                onChange={this.handleChange}
                                                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="rg">
                                            <small id="rglabel" className="form-text text-muted mb-2">
                                                RG
                                            </small>
                                            <MaskedInput
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="rg"
                                                name="rg"
                                                value={this.state.fields.rg}
                                                onChange={this.handleChange}
                                                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[\d\w]/]}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cep">
                                            <small id="ceplabel" className="form-text text-muted mb-2">
                                                CEP
                                            </small>
                                            <MaskedInput
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="cep"
                                                name="cep"
                                                value={this.state.fields.cep}
                                                onChange={this.handleChange}
                                                onBlur={this.autoCompleteAddress}
                                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="logradouro">
                                            <small id="logradourolabel" className="form-text text-muted mb-2">
                                                Logradouro
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="logradouro"
                                                name="logradouro"
                                                value={this.state.fields.logradouro}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="numero">
                                            <small id="numerolabel" className="form-text text-muted mb-2">
                                                Número
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="numero"
                                                name="numero"
                                                value={this.state.fields.numero}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="complemento">
                                            <small id="complementolabel" className="form-text text-muted mb-2">
                                                Complemento
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="complemento"
                                                name="complemento"
                                                value={this.state.fields.complemento}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="bairro">
                                            <small id="bairrolabel" className="form-text text-muted mb-2">
                                                Bairro
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="bairro"
                                                name="bairro"
                                                value={this.state.fields.bairro}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="cidade">
                                            <small id="cidadelabel" className="form-text text-muted mb-2">
                                                Cidade
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="cidade"
                                                name="cidade"
                                                value={this.state.fields.cidade}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="estado">
                                            <small id="estadolabel" className="form-text text-muted mb-2">
                                                Estado
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="estado"
                                                name="estado"
                                                value={this.state.fields.estado}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-2">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="uf">
                                            <small id="uflabel" className="form-text text-muted mb-2">
                                                UF
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="uf"
                                                name="uf"
                                                value={this.state.fields.uf}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="pais">
                                            <small id="paislabel" className="form-text text-muted mb-2">
                                                País
                                            </small>
                                            <input
                                                type="text"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="pais"
                                                name="pais"
                                                value={this.state.fields.pais}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="localizacao-cidades rounded bg-white">{this.state.localidades}</div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="nascimento">
                                            <small id="nascimentolabel" className="form-text text-muted mb-2">
                                                Data de Nascimento
                                            </small>
                                            <input
                                                type="date"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="nascimento"
                                                name="nascimento"
                                                value={this.state.fields.nascimento}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="telefone">
                                            <small id="telefonelabel" className="form-text text-muted mb-2">
                                                Celular
                                            </small>
                                            <MaskedInput
                                                type="phone"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="telefone"
                                                name="telefone"
                                                value={this.state.fields.telefone}
                                                onChange={this.handleChange}
                                                mask={['+', /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="whatsapp">
                                            <small id="whatsapplabel" className="form-text text-muted mb-2">
                                                Whatsapp
                                            </small>
                                            <MaskedInput
                                                type="phone"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="whatsapp"
                                                name="whatsapp"
                                                value={this.state.fields.whatsapp}
                                                onChange={this.handleChange}
                                                mask={['+', /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="senha">
                                            <small id="senhalabel" className="form-text text-muted mb-2">
                                                Senha
                                            </small>
                                            <input
                                                type="password"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="senha"
                                                name="senha"
                                                value={this.state.fields.senha}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="senhaConfirm">
                                            <small id="senhaConfirmlabel" className="form-text text-muted mb-2">
                                                Confirme a Senha
                                            </small>
                                            <input
                                                type="password"
                                                className="form-control rounded-0 m-0 border-top-0 border-left-0 border-right-0 p-0"
                                                id="senhaConfirm"
                                                name="senhaConfirm"
                                                value={this.state.fields.senhaConfirm}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mb-4 mt-5"/>
                        <div className="d-block text-right">
                            <Link to={"/admin/coordenadores"}
                                  onClick={this.cancelarCadastro}
                                  className="btn border-radius py-2 text-center bg-secondary text-white mr-3"
                            >
                                Cancelar
                            </Link>
                            <Link to={""}
                                  onClick={this.salvarCadastro}
                                  className="btn border-radius py-2 text-center bg-primary text-white"
                            >
                                Salvar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PerfisCoordenadoresCadastroEditarView;